<script setup>
import DesktopNavBar from '@/Pages/Public/Home/DesktopNavBar.vue'
import MobileNavBar from '@/Pages/Public/Home/MobileNavBar.vue'
import { ref, onMounted } from 'vue'

const isScrolled = ref(false)

onMounted(() => {
  checkScroll()
  window.addEventListener('scroll', checkScroll)
})

function checkScroll() {
  isScrolled.value = window.scrollY > 10
}
</script>

<template>
  <header
    class="fixed top-0 left-0 right-0 w-full z-50 transition-all duration-300 bg-indigo-900"
    :class="{
      'shadow-md': !isScrolled,
      'backdrop-blur-sm shadow-lg': isScrolled
    }"
  >
    <!-- Gradient border at the bottom -->
    <div
      class="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-blue-400 via-indigo-400 to-blue-500 opacity-0 transition-opacity duration-300"
      :class="{ 'opacity-100': isScrolled }"
    ></div>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <DesktopNavBar class="hidden md:block" />
      <MobileNavBar class="md:hidden" />
    </div>
  </header>
</template>
