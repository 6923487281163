<script setup>
import { ref, onMounted } from 'vue'

const isVisible = ref(false)

onMounted(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        isVisible.value = true
      }
    },
    { threshold: 0.1 }
  )

  const section = document.getElementById('contact')
  if (section) observer.observe(section)
})
</script>

<template>
  <section id="contact" class="relative py-24 bg-gray-50 overflow-hidden">
    <!-- Background elements -->
    <div class="absolute inset-0 bg-grid-pattern opacity-5"></div>
    <div
      class="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500"
    ></div>
    <div
      class="absolute -top-20 -right-20 w-64 h-64 bg-blue-400 rounded-full filter blur-3xl opacity-10"
    ></div>
    <div
      class="absolute -bottom-20 -left-20 w-64 h-64 bg-indigo-400 rounded-full filter blur-3xl opacity-10"
    ></div>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
      <div
        class="max-w-3xl mx-auto text-center mb-16 transition-all duration-700 transform"
        :class="isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'"
      >
        <h2 class="section-title text-gray-900">Contacta con nosotros</h2>
        <p class="section-subtitle">
          Estamos aquí para ayudarte. Atendemos WhatsApp para mayor comodidad.
        </p>
      </div>

      <div
        class="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto transition-all duration-700 delay-100 transform"
        :class="isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'"
      >
        <!-- Contact card: WhatsApp -->
        <div
          class="card group p-8 border border-gray-200 hover:border-blue-500 flex flex-col items-center text-center transition-all duration-300"
        >
          <div
            class="w-16 h-16 flex items-center justify-center bg-blue-100 rounded-full mb-6 group-hover:bg-blue-500 transition-colors duration-300"
          >
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-8 h-8 text-blue-600 group-hover:text-white transition-colors duration-300"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"
              ></path>
            </svg>
          </div>
          <h3 class="text-xl font-bold mb-2">WhatsApp</h3>
          <p class="text-gray-600 mb-6">Respuesta rápida a través de mensaje directo</p>
          <div class="mt-auto">
            <a
              href="https://wa.me/+34672326006?text=Hola! Me gustaría probar court control :)"
              target="_blank"
              rel="noopener noreferrer"
              class="inline-flex items-center text-blue-600 font-medium hover:text-blue-800 transition-colors duration-300"
            >
              +34 672 326 006
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 ml-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </a>
          </div>
        </div>

        <!-- Contact card: Email -->
        <div
          class="card group p-8 border border-gray-200 hover:border-blue-500 flex flex-col items-center text-center transition-all duration-300"
        >
          <div
            class="w-16 h-16 flex items-center justify-center bg-blue-100 rounded-full mb-6 group-hover:bg-blue-500 transition-colors duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8 text-blue-600 group-hover:text-white transition-colors duration-300"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              ></path>
            </svg>
          </div>
          <h3 class="text-xl font-bold mb-2">Correo electrónico</h3>
          <p class="text-gray-600 mb-6">Envíanos un correo con tus dudas o consultas</p>
          <div class="mt-auto">
            <a
              href="mailto:gonparpab@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              class="inline-flex items-center text-blue-600 font-medium hover:text-blue-800 transition-colors duration-300"
            >
              gonparpab@gmail.com
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 ml-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <!-- CTA Banner -->
      <div
        class="mt-20 relative overflow-hidden rounded-2xl transition-all duration-700 delay-200 transform"
        :class="isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'"
      >
        <div class="absolute inset-0 bg-gradient-to-r from-blue-600 to-indigo-700"></div>
        <div class="absolute inset-0 bg-grid-pattern opacity-10"></div>
        <div class="relative z-10 px-8 py-12 text-center text-white">
          <h3 class="text-2xl font-bold mb-4">¿Listo para simplificar la gestión de tu club?</h3>
          <p class="text-lg text-white/80 max-w-2xl mx-auto mb-8">
            Prueba Court Control hoy mismo y descubre cómo podemos ayudarte a optimizar tus
            operaciones deportivas.
          </p>
          <a
            href="https://wa.me/+34672326006?text=Hola! Me gustaría solicitar una demo de Court Control :)"
            target="_blank"
            rel="noopener noreferrer"
            class="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-blue-600 bg-white rounded-lg shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300"
          >
            Solicita una demo gratuita
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.bg-grid-pattern {
  background-image: radial-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px);
  background-size: 30px 30px;
}
</style>
