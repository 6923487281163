<script setup>
import Logo from '@/Shared/Logo.vue'
import { Link } from '@inertiajs/vue3'
import { ref, onMounted, watch } from 'vue'

const activeSection = ref('welcome')
const isMenuOpen = ref(false)

onMounted(() => {
  const hash = window.location.hash.substring(1)
  if (hash) {
    activeSection.value = hash
  }
})

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

watch(isMenuOpen, () => {
  if (isMenuOpen.value) {
    document.body.classList.add('overflow-hidden')
  } else {
    document.body.classList.remove('overflow-hidden')
  }
})

const closeMenu = () => {
  isMenuOpen.value = false
}

const isActive = (section) => {
  return activeSection.value === section
}
</script>

<template>
  <div class="relative w-full">
    <nav class="flex justify-between items-center container mx-auto py-4 px-4 z-50 w-full">
      <!-- Logo -->
      <div id="logo" class="flex items-center z-20">
        <Link href="/">
          <Logo class="text-2xl font-bold -ml-4" />
        </Link>
      </div>

      <!-- Mobile menu button -->
      <button
        @click="toggleMenu"
        class="lg:hidden flex items-center justify-center z-20 focus:outline-none"
        :aria-expanded="isMenuOpen"
        aria-controls="mobile-menu"
        aria-label="Toggle menu"
      >
        <svg
          class="w-6 h-6 transition-colors duration-200 text-white"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            v-if="!isMenuOpen"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
          <path
            v-else
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>

      <!-- Mobile menu -->
      <div
        id="mobile-menu"
        class="fixed inset-0 z-50 bg-indigo-900/95 backdrop-blur-sm transform transition-transform duration-300 ease-in-out h-screen overflow-hidden"
        :class="isMenuOpen ? 'translate-x-0' : 'translate-x-full'"
      >
        <div class="pt-8 px-6 pb-8 h-full flex flex-col items-center overflow-hidden">
          <!-- Close button -->
          <button
            @click="closeMenu"
            class="absolute top-6 right-6 text-white hover:text-blue-500 transition-colors duration-200"
            aria-label="Cerrar menú"
          >
            <svg
              class="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          <div class="flex flex-col space-y-2 w-full mt-8 items-start px-2">
            <Link
              href="/#welcome"
              @click="closeMenu"
              class="py-3 px-4 text-xl font-medium transition-colors duration-200"
              :class="isActive('welcome') ? 'text-white' : 'text-indigo-300 hover:text-white'"
              title="Inicio"
              rel="noopener noreferrer"
            >
              Inicio
            </Link>
            <Link
              href="/#product"
              @click="closeMenu"
              class="py-3 px-4 text-xl font-medium transition-colors duration-200"
              :class="isActive('product') ? 'text-white' : 'text-indigo-300 hover:text-white'"
              title="Producto"
              rel="noopener noreferrer"
            >
              Producto
            </Link>
            <Link
              href="/#contact"
              @click="closeMenu"
              class="py-3 px-4 text-xl font-medium transition-colors duration-200"
              :class="isActive('contact') ? 'text-white' : 'text-indigo-300 hover:text-white'"
              title="Contacto"
              rel="noopener noreferrer"
            >
              Contacto
            </Link>

            <div class="pt-6 mt-6 border-t border-indigo-800 w-full">
              <a
                href="/login"
                target="_blank"
                class="block w-full py-3 px-4 text-center text-gray-900 bg-blue-400 rounded-lg font-medium hover:bg-blue-300 transition-colors duration-200"
                title="Iniciar sesión"
                rel="noopener noreferrer"
              >
                Iniciar sesión
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
