<script setup>
import Logo from '@/Shared/Logo.vue'
import TextInput from '@/Shared/TextInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'

let form = useForm({
  email: '',
  password: '',
  remember: false
})

function login() {
  form.post('/login')
}
</script>

<template>
  <div class="flex items-center justify-center p-6 min-h-screen bg-indigo-800">
    <div class="w-full max-w-md">
      <div>
        <Logo
          class="text-center text-4xl justify-center"
          logo-classes="w-20 h-20"
          text-classes="text-2xl lg:text-3xl"
        />
      </div>
      <form class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden" @submit.prevent="login">
        <div class="px-10 py-12">
          <TextInput
            v-model="form.email"
            :error="form.errors.email"
            label="Email"
            type="email"
            autofocus
            autocapitalize="off"
          />
          <TextInput
            v-model="form.password"
            :error="form.errors.password"
            class="mt-6"
            label="Password"
            type="password"
          />
          <label class="flex items-center mt-6 select-none" for="remember">
            <input id="remember" v-model="form.remember" class="mr-1" type="checkbox" />
            <span class="text-sm">Mantenerme conectado</span>
          </label>
        </div>
        <div class="flex px-10 py-4 bg-gray-100 border-t border-gray-100">
          <LoadingButton :loading="form.processing" class="btn-indigo ml-auto" type="submit">
            Login
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
