<script setup>
import { ref, onMounted } from 'vue'
import { Head } from '@inertiajs/vue3'

import constructionImg from '@assets/images/under-construction.svg'

const messages = [
  'Los desarrolladores están en un tie-break con el código 🧑‍💻🏆',
  'Esta página está practicando su revés. Vuelve en un momento ⏳💪',
  'Estamos ajustando la red. Pronto estará todo listo para jugar 🛠️🕸️',
  'Esta sección está en entrenamiento intensivo. ¡Regresa pronto con energía! 🏋️‍♂️🎾',
  'El marcador se está actualizando... ¡no te vayas! 📊⏱️',
  'Nuestro equipo está calentando para desplegar esta funcionalidad 🔥🧑‍💻',
  'Esta cancha está en mantenimiento. Volverá a estar lista para jugar en breve 🏟️⚠️'
]

const randomMessage = ref('')
const loadingProgress = ref(0)
const showConfetti = ref(false)

onMounted(() => {
  // Seleccionar un mensaje aleatorio
  randomMessage.value = messages[Math.floor(Math.random() * messages.length)]

  // Simular progreso de carga
  const interval = setInterval(() => {
    loadingProgress.value += 1
    if (loadingProgress.value >= 100) {
      clearInterval(interval)
      setTimeout(() => {
        showConfetti.value = true
      }, 500)
    }
  }, 30)
})
</script>

<template>
  <Head title="En construcción" />

  <div
    class="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8 relative overflow-hidden"
  >
    <!-- Confetti animation when progress reaches 100% -->
    <div v-if="showConfetti" class="confetti-container">
      <div
        v-for="n in 50"
        :key="n"
        class="confetti"
        :style="{
          '--fall-delay': `${Math.random() * 5}s`,
          '--fall-duration': `${Math.random() * 5 + 3}s`,
          '--left-pos': `${Math.random() * 100}%`,
          '--bg-color': `hsl(${Math.random() * 360}, 70%, 60%)`
        }"
      ></div>
    </div>

    <div class="max-w-md w-full space-y-8 text-center relative z-10">
      <div>
        <img
          class="mx-auto h-64 w-auto animate-bounce-slow"
          :src="constructionImg"
          alt="En construcción"
        />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-indigo-900">
          ¡Estamos trabajando en ello!
        </h2>
        <p class="mt-2 text-center text-xl text-indigo-700">
          {{ randomMessage }}
        </p>
      </div>

      <div class="mt-8">
        <div class="relative pt-1">
          <div class="flex mb-2 items-center justify-between">
            <div>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200"
              >
                Progreso de construcción
              </span>
            </div>
            <div class="text-right">
              <span class="text-xs font-semibold inline-block text-indigo-600">
                {{ loadingProgress }}%
              </span>
            </div>
          </div>
          <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-200">
            <div
              :style="{ width: `${loadingProgress}%` }"
              class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-blue-500 to-indigo-600 transition-all duration-500 ease-out"
            ></div>
          </div>
        </div>
      </div>

      <div class="flex justify-center mt-8">
        <a
          href="/"
          class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300 transform hover:scale-105"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
              clip-rule="evenodd"
            />
          </svg>
          Volver al inicio
        </a>
      </div>
    </div>

    <!-- Decorative elements -->
    <div
      class="absolute top-1/4 left-10 w-16 h-16 bg-blue-500 rounded-full opacity-20 animate-pulse"
    ></div>
    <div
      class="absolute bottom-1/4 right-10 w-24 h-24 bg-indigo-500 rounded-full opacity-20 animate-pulse animation-delay-1000"
    ></div>
    <div
      class="absolute top-3/4 left-1/3 w-12 h-12 bg-indigo-300 rounded-full opacity-20 animate-pulse animation-delay-2000"
    ></div>
  </div>
</template>

<style scoped>
.animate-bounce-slow {
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-5%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animation-delay-1000 {
  animation-delay: 1s;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

/* Confetti animation */
.confetti-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  overflow: hidden;
}

.confetti {
  position: absolute;
  top: -10px;
  width: 10px;
  height: 20px;
  background-color: var(--bg-color);
  opacity: 0.7;
  left: var(--left-pos);
  animation: fall var(--fall-duration) var(--fall-delay) linear infinite;
}

@keyframes fall {
  0% {
    transform: translateY(-10px) rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(720deg);
    opacity: 0;
  }
}
</style>
