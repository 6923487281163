<script setup>
import { useConfirm } from '@/Composables/useConfirm'
import { useDate } from '@/Composables/useDate'
import Icon from '@/Shared/Icon.vue'
import { Link, useForm, router } from '@inertiajs/vue3'
import { computed, ref } from 'vue'

const props = defineProps({
  group: {
    type: Object,
    required: true
  },
  currentParticipant: {
    type: Object,
    required: true
  },
  participants: {
    type: Array,
    required: true
  }
})

let form = useForm({
  availability: []
})

function update() {
  form.put(`/phases/groups/${props.group.id}/participant/${props.currentParticipant.id}/update`, {
    preserveScroll: true,
    onSuccess: () => (opened.value = null)
  })
}

const opened = ref(null)

const availabilityLabel = computed(() => {
  const dateStart = useDate(props.group.phase.date_start).format('DD-MM-YYYY')
  const dateEnd = useDate(props.group.phase.date_end).format('DD-MM-YYYY')

  return `Disponibilidad del participante entre el ${dateStart} y el ${dateEnd}`
})

function toggle(participantId) {
  if (opened.value === participantId) {
    opened.value = null
  } else {
    opened.value = participantId
  }
}

async function removeAvailability(availability) {
  if (await useConfirm('¿Estas seguro de que quieres eliminar esta disponibilidad?')) {
    router.put(
      `/phases/groups/${props.group.id}/participant/${props.currentParticipant.id}/availability/delete`,
      {
        date_start: useDate(availability.date_start).format('YYYY-MM-DD HH:mm:ss'),
        date_end: useDate(availability.date_end).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        preserveScroll: true
      }
    )
  }
}

function linkWithQuery(path) {
  const currentSearchParams = new URLSearchParams(window.location.search)

  const queryString = currentSearchParams.toString()
  return queryString ? `${path}?${queryString}` : path
}
</script>

<template>
  <div>
    <div class="max-w-5xl mx-auto">
      <h1 class="mb-8 text-xl font-bold">
        <span> {{ group.phase.ranking.name }} / {{ group.phase.name }} / {{ group.name }} </span>
      </h1>

      <div class="bg-white rounded-md shadow my-6 w-full overflow-auto">
        <div class="grid grid-cols-2 text-left font-bold">
          <div class="pb-4 pt-6 px-6">Participante</div>
          <div class="pb-4 pt-6 px-6">Disponibilidad</div>
        </div>

        <div
          v-for="participant in group.participants"
          :key="participant.id"
          class="border-t cursor-pointer"
        >
          <div
            class="grid grid-cols-2 hover:bg-gray-100 w-full overflow-auto"
            :class="participant.id === currentParticipant.id ? 'font-bold' : ''"
            @click="toggle(participant.id)"
          >
            <div class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ participant.participant.fullname || participant.participant.name }}
            </div>
            <div class="flex items-center px-6 py-4 focus:text-indigo-500">
              <span>
                {{ participant.id === currentParticipant.id ? 'Editar' : 'Ver' }}
              </span>
            </div>
          </div>

          <div v-if="participant.id === opened" class="grid grid-cols-2">
            <div class="col-span-2 px-6 py-4 border-t border-b">
              <form v-if="participant.id === currentParticipant.id" @submit.prevent="update">
                <div class="text-sm">
                  <label class="form-label"> {{ availabilityLabel }}:</label>
                  <ul class="mt-2 pl-4">
                    <li
                      v-for="(availabilities, date) in participant.availabilities"
                      :key="date"
                      class="list-disc mt-4"
                    >
                      {{ `${useDate(date).format('DD-MM-YYYY')}` }}
                      <ul class="pl-2">
                        <li v-for="availability in availabilities" :key="availability.id">
                          <div class="flex items-center gap-2">
                            <span>
                              {{ `${useDate(availability.date_start).format('HH:mm')}` }} -
                              {{ `${useDate(availability.date_end).format('HH:mm')}` }}
                            </span>
                            <span class="w-5 flex-shrink-0">
                              <Icon
                                name="Trash"
                                class="w-5 h-5 text-red-500"
                                @click="removeAvailability(availability)"
                              />
                            </span>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="list-disc mt-4">
                      <Link
                        class="text-indigo-400 hover:text-indigo-600"
                        :href="
                          linkWithQuery(
                            `/phases/groups/${group.id}/participant/${participant.id}/availability`
                          )
                        "
                      >
                        Agregar disponibilidad
                      </Link>
                    </li>
                  </ul>
                </div>
              </form>
              <div v-else>
                <div class="flex flex-wrap">
                  <div class="text-sm">
                    <label class="form-label"> {{ availabilityLabel }}:</label>
                    <ul class="mt-2 pl-4">
                      <li
                        v-for="(availabilities, date) in participant.availabilities"
                        :key="date"
                        class="list-disc mt-4"
                      >
                        {{ `${useDate(date).format('DD-MM-YYYY')}` }}
                        <ul class="pl-2">
                          <li v-for="availability in availabilities" :key="availability.id">
                            <div
                              class="flex items-center gap-2"
                              :class="{ 'text-green-600 font-bold': availability.coincidence }"
                            >
                              <span>
                                {{ `${useDate(availability.date_start).format('HH:mm')}` }} -
                                {{ `${useDate(availability.date_end).format('HH:mm')}` }}

                                {{
                                  availability.coincidence
                                    ? '(Coincides en esta disponibilidad)'
                                    : ''
                                }}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li v-if="participant.availabilities.length === 0" class="list-disc">
                        Todavía no hay disponibilidades
                      </li>
                    </ul>
                  </div>
                  <ul class="mt-4">
                    <li v-for="player in participant.players" :key="player.id">
                      {{ player.name }}:
                      <a class="text-indigo-500" :href="`tel:+34${player.phone}`">
                        {{ player.phone }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="group.participants.length === 0" class="px-6 py-4">
          No hay participantes todavía.
        </div>
      </div>

      <div>A Pedralba: <a class="text-indigo-500" :href="`tel:+34981794488`">981 79 44 88</a></div>
    </div>
  </div>
</template>
