<script setup>
import Logo from '@/Shared/Logo.vue'
import { Link } from '@inertiajs/vue3'
import { ref, onMounted } from 'vue'

const activeSection = ref('welcome')

onMounted(() => {
  // Set active section based on URL hash
  const hash = window.location.hash.substring(1)
  if (hash) {
    activeSection.value = hash
  }

  // Update active section on scroll
  window.addEventListener('scroll', handleScroll)
})

const handleScroll = () => {
  const sections = ['welcome', 'product', 'contact']

  for (const section of sections) {
    const element = document.getElementById(section)
    if (element) {
      const rect = element.getBoundingClientRect()
      if (rect.top <= 200 && rect.bottom >= 200) {
        activeSection.value = section
        break
      }
    }
  }
}

const isActive = (section) => {
  return activeSection.value === section
}
</script>

<template>
  <div class="w-full">
    <nav class="flex justify-between items-center container mx-auto py-4 w-full">
      <div id="logo" class="flex items-center">
        <Link href="/">
          <Logo class="text-2xl font-bold -ml-4" />
        </Link>
      </div>
      <div id="menu">
        <div class="hidden lg:flex lg:gap-x-8">
          <a
            href="/#welcome"
            class="nav-link relative px-2 py-1 text-sm font-medium transition-colors duration-200"
            :class="isActive('welcome') ? 'text-white' : 'text-indigo-300 hover:text-white'"
            title="Inicio"
            rel="noopener noreferrer"
          >
            Inicio
            <span
              class="absolute bottom-0 left-0 h-0.5 bg-blue-500 transition-all duration-300"
              :class="isActive('welcome') ? 'w-full' : 'w-0'"
            ></span>
          </a>
          <a
            href="/#product"
            class="nav-link relative px-2 py-1 text-sm font-medium transition-colors duration-200"
            :class="isActive('product') ? 'text-white' : 'text-indigo-300 hover:text-white'"
            title="Producto"
            rel="noopener noreferrer"
          >
            Producto
            <span
              class="absolute bottom-0 left-0 h-0.5 bg-blue-500 transition-all duration-300"
              :class="isActive('product') ? 'w-full' : 'w-0'"
            ></span>
          </a>
          <a
            href="/#contact"
            class="nav-link relative px-2 py-1 text-sm font-medium transition-colors duration-200"
            :class="isActive('contact') ? 'text-white' : 'text-indigo-300 hover:text-white'"
            title="Contacto"
            rel="noopener noreferrer"
          >
            Contacto
            <span
              class="absolute bottom-0 left-0 h-0.5 bg-blue-500 transition-all duration-300"
              :class="isActive('contact') ? 'w-full' : 'w-0'"
            ></span>
          </a>
          <a
            href="/login"
            target="_blank"
            class="ml-4 px-4 py-1.5 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors duration-300"
            title="Iniciar sesión"
            rel="noopener noreferrer"
          >
            Iniciar sesión
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<style scoped>
.nav-link:hover span {
  width: 100%;
}
</style>
