<script setup>
import { Link } from '@inertiajs/vue3'
import { ref, onMounted } from 'vue'
import imgLessons from '@assets/images/p8.png'
import imgRankings from '@assets/images/p10.png'
import imgGames from '@assets/images/p11.png'

const features = [
  {
    id: 1,
    title: 'Clases y reservas',
    description:
      'Gestiona tu agenda con facilidad: asigna horarios, permite reservas online y mantén el control de asistencia. Si alguien falta notifica a los alumnos del mismo nivel para rellenar el hueco que falta. Los alumnos pueden reservar y cancelar en solo unos clics.',
    image: imgLessons,
    icon: 'M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z'
  },
  {
    id: 2,
    title: 'Rankings',
    description:
      'Organiza rankings de tenis y pádel sin complicaciones. Inicia la fase de ranking y la app se encargará de gestionar ascensos y descensos en base a los resultatos. Todo desde una única plataforma intuitiva.',
    image: imgRankings,
    icon: 'M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a1.125 1.125 0 010 2.25M9.497 0h5.006a1.125 1.125 0 010 2.25H9.497m5.007 0H9.497m5.007 0h5.006a1.125 1.125 0 010 2.25h-5.006m-5.006 0H4.49m5.007 0h-5.006a1.125 1.125 0 010-2.25h5.006M4.49 8.25h5.006a1.125 1.125 0 010 2.25H4.49m5.007 0h5.006a1.125 1.125 0 010-2.25H9.497m5.007 0H9.497m0 0H4.49m5.007 0h5.006'
  },
  {
    id: 3,
    title: 'Gestión de partidos',
    description:
      'Crea partidos en segundos, añade jugadores y lleva el control de resultados. Organiza partidas abiertas o privadas y deja que la app se encargue de las reservas y notificaciones.',
    image: imgGames,
    icon: 'M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5'
  }
]

const activeFeature = ref(0)
const isVisible = ref(false)

onMounted(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        isVisible.value = true
      }
    },
    { threshold: 0.1 }
  )

  const section = document.getElementById('product')
  if (section) observer.observe(section)
})
</script>

<template>
  <section id="product" class="py-24 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-16">
        <h2 class="section-title text-black">Funcionalidades principales</h2>
        <p class="section-subtitle">
          Todo lo que necesitas para gestionar tu día a día en una sola plataforma.
        </p>
      </div>

      <!-- Feature tabs -->
      <div class="flex flex-wrap justify-center gap-4 mb-12">
        <button
          v-for="(feature, index) in features"
          :key="feature.id"
          @click="activeFeature = index"
          class="px-5 py-2.5 rounded-md text-sm font-medium transition-all duration-300"
          :class="
            activeFeature === index
              ? 'bg-blue-600 text-white shadow-lg'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          "
        >
          {{ feature.title }}
        </button>
      </div>

      <!-- Feature content -->
      <div
        class="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center transition-all duration-500 transform"
        :class="isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'"
      >
        <!-- Feature description -->
        <div class="order-2 lg:order-1">
          <div class="flex items-center mb-4">
            <div class="p-2 bg-blue-100 rounded-lg mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-blue-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  :d="features[activeFeature].icon"
                />
              </svg>
            </div>
            <h3 class="text-2xl font-bold">{{ features[activeFeature].title }}</h3>
          </div>

          <p class="text-lg text-gray-600 mb-8">
            {{ features[activeFeature].description }}
          </p>

          <div class="space-y-4">
            <div class="flex items-start">
              <div class="flex-shrink-0 mt-1">
                <svg
                  class="h-5 w-5 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <p class="ml-3 text-gray-700">Interfaz intuitiva y fácil de usar</p>
            </div>
            <div class="flex items-start">
              <div class="flex-shrink-0 mt-1">
                <svg
                  class="h-5 w-5 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <p class="ml-3 text-gray-700">Actualizaciones en tiempo real</p>
            </div>
            <div class="flex items-start">
              <div class="flex-shrink-0 mt-1">
                <svg
                  class="h-5 w-5 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <p class="ml-3 text-gray-700">Acceso desde cualquier dispositivo</p>
            </div>
          </div>
        </div>

        <!-- Feature image -->
        <div class="order-1 lg:order-2">
          <div class="relative">
            <div
              class="absolute inset-0 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-2xl transform rotate-3 scale-105 opacity-10"
            ></div>
            <div
              class="card overflow-hidden relative z-10 transform transition-all duration-500"
              :class="
                activeFeature === 0 ? 'rotate-0' : activeFeature === 1 ? 'rotate-1' : '-rotate-1'
              "
            >
              <img
                :src="features[activeFeature].image"
                :alt="features[activeFeature].title"
                class="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-16 text-center">
        <Link href="#contact" class="btn-primary"> Solicita una demo </Link>
      </div>
    </div>
  </section>
</template>
