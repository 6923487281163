<script setup>
import { ref, onMounted } from 'vue'

import imgDashboard from '@assets/images/p10.png'

const isAnimated = ref(false)

onMounted(() => {
  setTimeout(() => {
    isAnimated.value = true
  }, 100)
})
</script>

<template>
  <section
    id="welcome"
    class="relative min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-900 to-indigo-800 text-white overflow-hidden"
  >
    <!-- Background pattern/grid effect -->
    <div class="absolute inset-0 bg-grid-pattern opacity-10"></div>

    <!-- Animated gradient circles -->
    <div
      class="absolute -top-20 -left-20 w-96 h-96 bg-blue-500 rounded-full filter blur-3xl opacity-20 animate-blob"
    ></div>
    <div
      class="absolute top-1/2 -right-20 w-80 h-80 bg-indigo-400 rounded-full filter blur-3xl opacity-20 animate-blob animation-delay-2000"
    ></div>
    <div
      class="absolute -bottom-20 left-1/3 w-72 h-72 bg-indigo-300 rounded-full filter blur-3xl opacity-20 animate-blob animation-delay-4000"
    ></div>

    <div class="container mx-auto px-4 py-24 relative z-10">
      <div class="max-w-7xl mx-auto flex flex-col lg:flex-row items-center gap-12">
        <div
          class="lg:w-1/2 transition-all duration-1000 transform"
          :class="isAnimated ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'"
        >
          <h1 class="text-5xl md:text-6xl font-bold leading-tight mb-6">
            Gestión deportiva <span class="text-blue-400">simplificada</span>
          </h1>
          <p class="text-xl text-indigo-200 mb-8 max-w-xl">
            Descubre la herramienta ideal para entrenadores y clubes deportivos. Gestiona todo desde
            un único lugar.
          </p>
          <div class="flex flex-wrap gap-4">
            <a href="#product" class="btn-primary"> Saber más </a>
            <a href="#contact" class="btn-secondary"> Solicitar demo </a>
          </div>
        </div>

        <div
          class="lg:w-1/2 transition-all duration-1000 delay-300 transform"
          :class="isAnimated ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'"
        >
          <div class="relative">
            <!-- Dashboard mockup with shadow and border -->
            <div
              class="relative z-10 bg-white rounded-2xl shadow-2xl overflow-hidden border border-gray-200 transform rotate-1 hover:rotate-0 transition-all duration-500"
            >
              <div class="h-6 bg-gray-100 flex items-center px-4">
                <div class="flex space-x-2">
                  <div class="w-3 h-3 bg-red-500 rounded-full"></div>
                  <div class="w-3 h-3 bg-yellow-500 rounded-full"></div>
                  <div class="w-3 h-3 bg-green-500 rounded-full"></div>
                </div>
              </div>
              <img :src="imgDashboard" alt="Court Control Dashboard" class="w-full" />
            </div>

            <!-- Decorative elements -->
            <div
              class="absolute -bottom-6 -right-6 w-24 h-24 bg-blue-500 rounded-full opacity-20 z-0"
            ></div>
            <div
              class="absolute -top-6 -left-6 w-16 h-16 bg-indigo-500 rounded-full opacity-20 z-0"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Down arrow -->
    <div class="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
      <a href="#product" class="text-white opacity-70 hover:opacity-100 transition-opacity">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 14l-7 7m0 0l-7-7m7 7V3"
          />
        </svg>
      </a>
    </div>
  </section>
</template>

<style scoped>
.bg-grid-pattern {
  background-image: radial-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 30px 30px;
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}
</style>
